var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"status",attrs:{"size":"lg","id":'modal-inventory' + _vm.contractInfo.id,"ok-only":"","hide-footer":"","no-enforce-focus":true}},[_c('b-card-text',[_c('ValidationObserver',{ref:"observerReference",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h3',[_vm._v("Información de la Reservación")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Categoria"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Categoria"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.roomtypecode),callback:function ($$v) {_vm.roomtypecode=$$v},expression:"roomtypecode"}},[_c('option',{attrs:{"value":"null"}},[_vm._v("Seleccione")]),_vm._l((_vm.room),function(roomsItems){return _c('option',{key:roomsItems.room__id,domProps:{"value":roomsItems.room__code}},[_vm._v(" "+_vm._s(roomsItems.room__code)+" | "+_vm._s(roomsItems.room__name)+" ")])})],2)],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"N° de Cuartos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"N° Cuartos"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.numRooms),callback:function ($$v) {_vm.numRooms=$$v},expression:"numRooms"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Ocupación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Ocupación"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},on:{"keypress":_vm.onlyNumber},model:{value:(_vm.occupation),callback:function ($$v) {_vm.occupation=$$v},expression:"occupation"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Honey Money"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Honey Money"}},[_c('b-form-input',{attrs:{"type":"number","step":"0.01","state":errors[0] ? false : valid ? true : null},on:{"blur":function($event){return _vm.formatAmount(_vm.honeyMoney)},"keypress":_vm.onlyNumberWithDecimal},model:{value:(_vm.honeyMoney),callback:function ($$v) {_vm.honeyMoney=$$v},expression:"honeyMoney"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"adultos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Max Adultos*"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.adultsNumber),callback:function ($$v) {_vm.adultsNumber=$$v},expression:"adultsNumber"}},_vm._l((_vm.adultOptions),function(adulto,index){return _c('option',{key:index,domProps:{"value":adulto.value}},[_vm._v(" "+_vm._s(adulto.title)+" ")])}),0),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"niños","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Max Niños*"}},[_c('b-form-select',{attrs:{"options":_vm.childrenOptions,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.numberChildren),callback:function ($$v) {_vm.numberChildren=$$v},expression:"numberChildren"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Permitir Niños"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"state":errors[0] ? false : valid ? true : null,"label":"Permitir Niños"}},[_c('b-form-checkbox',{staticClass:"text-center",attrs:{"true-value":"1","false-value":"0"},model:{value:(_vm.hasChildren),callback:function ($$v) {_vm.hasChildren=$$v},expression:"hasChildren"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"disabled":invalid || _vm.isSaving,"variant":"primary"},on:{"click":function($event){return _vm.setAllomentRoom(_vm.room)}}},[(_vm.isSavingLoad)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Guardar ")],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }