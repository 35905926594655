<template>
  <b-modal
    size="sm"
    :id="'modal-allotment' + room.roomId"
   
    ok-only
    hide-footer
  >
  
    <b-card-text>
      <validation-observer tag="form" v-slot="{ invalid }" >
    
      <b-row class="mb-2">
        <b-col md="12" class="text-left mb-2">
        <div>
            <div class="font-weight-bold">Modificar Inventario: </div>
              <div class="font-weight-bold"> {{room.roomName}}</div>
              </div>
            </b-col>
        <b-col md="12" class="text-center">
        <div class="mb-1 ">
          <div  class="float-left font-weight-bold margin-2"> Habitaciones: </div>
                <div  class="float-rigth text-right">
                    <button  type="button" class="minus-qty"  @click="handlerQty('minus', room)">-</button >
                    <span class="qty-number" >{{ available }}</span>
                    <button  type="button" class="plus-qty"  @click="handlerQty('plus', room)"> + </button>
                </div>
              </div>
              <b-alert variant="danger"  v-if="isValid" show>
                    <div class="alert-body text-center">
                      <span><strong> Máximo permitido de habitaciones: {{ maxRoom+room.available }}</strong></span>
                    
                    </div>
            </b-alert>
            </b-col>
            <b-col md="12">
              <validation-provider
                name="Motivo"
                rules="required|max:50|min:20"
              >
                <b-form-group
                  label="Motivo"
                  slot-scope="{ valid, errors }"
                  class="font-weight-bold"
                >
                  <b-form-textarea
                    v-model="note"
                    placeholder="Escribe la nota"
                    :maxlength="50"
                    :minlength="20"
                    rows="2"
                    :state="errors[0] ? false : valid ? true : null"
                   
                  />
                  <b-form-invalid-feedback v-for="error in errors" :key="error">
                    {{ error }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
        <b-col md="12" class="text-center">
   
          <b-col md="12">
            <div class="text-center">
              <b-button
                :disabled="invalid || isSaving"
                variant="primary"
                @click="setAllomentRoom(room)"
              >
                <b-spinner small v-if="isSaving" />Actualizar
              </b-button>
            </div>
          </b-col>

      </b-col>
      </b-row>
       </validation-observer>
    </b-card-text>
  </b-modal>
</template>

<script>

import { mapActions, mapMutations, mapState } from "vuex";
import { currentDate } from "@/helpers/helpers";

export default {
  async mounted() {},
  props: {
    room: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      isSaving: false,
      available: this.room.available,
      fechaActual: currentDate(),
      invalid: false,
      isValid:false,
      note:"",
      maxRoom:20
     
    };
  },
  watch: {
    room () {
     this.available=this.room.available
      this.note=""
      this.isSaving=false
      this.isValid=false
    }
  },
    
  computed: {
    ...mapState("auth", ["user"]),

   
  },
  methods: {
    ...mapActions("groups", ["fetchCancelRoom", "fetchRoomBooking"]),

    ...mapMutations("groups", [
      "addArrayNinios",
      "setAgeChild",
      "removeAgeChild",
      "setTotalNights",
    ]),
    handlerQty(operator, room){
      if(operator === 'plus'){
        this.available += 1
      }
      if(operator === 'minus' && this.available>0){
        this.available -= 1
      }
     
    },

    async setAllomentRoom(room) {
     let totalBalance=this.available - room.available
      if( 20 < totalBalance){
        this.isValid=true
        return false
      }else{
        this.isValid=false
      }
      
    
      const payload = {
        room: room,
        groupID: room.group,
        totalRooms:this.available,
        note: this.note,
        user: this.user.idUser,
        available:room.available
        
      };
      await this.$emit("send-allotment",payload);
        this.isSaving=true
       
    }
 
  },
};
</script>


<style>

.minus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  margin-inline-end: .5rem;   
  font-size: 1.5rem;  
}
.minus-qty:disabled{
  cursor: not-allowed;
}
.plus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;      
  font-size: 1.5rem;
  
}
.minus-qty:hover, .plus-qty:hover{
  border: 1px solid #030303;

}
.plus-qty:disabled{
  cursor: not-allowed;
}
.qty-number{
  font-weight: 600;
  margin: .5rem;
  color: black;
}
.float-left.labelText {
    padding-top: 6px;
}
.margin-2{
margin: 2px 0 0 0;
}
</style>